import React from 'react';
import { Grid } from '@mui/material';
import hero_logo from '../assets/vici-logo-text.svg';
import hero_tagline from '../assets/hero_tagline.svg';
import hero_video_hevc from '../assets/shelf_stocking_animation_desktop_1080p_hevc.mp4';
import hero_video_hevc_mobile from '../assets/shelf_stocking_animation_mobile_1080p_hevc.mp4';
import hero_video_hevc_4k from '../assets/shelf_stocking_animation_desktop_4k_hevc.mp4';
import hero_video_h264 from '../assets/shelf_stocking_animation_desktop_1080p_h264.mp4';
import hero_video_h264_mobile from '../assets/shelf_stocking_animation_mobile_1080p_h264.mp4';
import hero_video_h264_4k from '../assets/shelf_stocking_animation_desktop_4k_h264.mp4';
import * as social from '../constants';

function Home (props) {
    return (
        <React.Fragment>
            <Grid 
                container 
                direction="row"
                justifyContent="space-between"
                style={{'position': 'absolute', 'height': '100%'}}
                >
                <Grid  
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    >
                    <Grid item xs={8} sm={5} md={3} lg={2} xl={1.5} p={4}>
                        <img id="logo" src={hero_logo} alt="Vici Robotics Logo"></img>
                    </Grid>
                    <Grid item xs={4} sm={5} md={7} lg={8}>
                        {/* Offset */}
                    </Grid>
                    <Grid item xs={12} sm={10} md={9} lg={5} xl={4} p={4} marginTop={-4}>
                        <img id="tagline" src={hero_tagline} alt="Inventory Automation, Robotics for the Future of Retail"></img>
                    </Grid>
                </Grid>

                <Grid 
                    container
                    item 
                    marginTop={'auto'}
                    pb={{xs: 12, sm: 8}}
                    pr={{xs: 4, sm: 8}}>
                    <a className="cta-button" href={social.linkedin_link} target="_blank" rel="noreferrer" 
                        style={{'marginLeft': 'auto'}}>
                        <Grid container spacing={2}>
                            <Grid item>
                                Connect with us
                            </Grid>
                            <Grid item>
                                <i className="fa-brands fa-linkedin fa-lg"></i>
                            </Grid>
                        </Grid>
                    </a>
                </Grid>
            </Grid>
            <div style={{'background': 'rgba(255,255,255,0.3)', 'position': 'absolute', 'height': '100vh', 'width': '100vw', 'zIndex': -1 }}></div>
            <video autoPlay playsInline muted loop style={{ 'width': '100vw', 'height': '100vh', 'position': 'absolute', 'zIndex': -2, 'objectFit': 'cover'}}>
                <source src={hero_video_hevc_4k} media="only screen and (min-width:1081px)" type='video/mp4; codecs="hvc1"'></source>
                <source src={hero_video_h264_4k} media="only screen and (min-width:1081px)" type='video/mp4; codecs="avc1"'></source>
                <source src={hero_video_hevc} media="only screen and (min-width:569px)" type='video/mp4; codecs="hvc1"'></source>
                <source src={hero_video_h264} media="only screen and (min-width:569px)" type='video/mp4; codecs="avc1"'></source>
                <source src={hero_video_hevc_mobile} media="only screen and (max-width: 568px)" type='video/mp4; codecs="hvc1"'></source> 
                <source src={hero_video_h264_mobile} media="only screen and (max-width: 568px)" type='video/mp4; codecs="avc1"'></source> 
            </video>
        </React.Fragment>
    );
}

export default Home;